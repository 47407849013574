import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    FC,
    ReactNode
} from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';

interface SolBalanceContextProps {
    balance: number;
    refreshBalance: () => void;
}

const SolBalanceContext = createContext<SolBalanceContextProps | undefined>(undefined);

export const useSolBalance = () => {
    const context = useContext(SolBalanceContext);
    if (!context) {
        console.error('useSolBalance must be used within a TokensProvider');
    }
    return context;
};

interface TokensProviderProps {
    children: ReactNode;
}

export const TokensBalanceProvider: FC<TokensProviderProps> = ({ children }) => {
    const RPC = process.env.NEXT_PUBLIC_RPC || 'https://api.mainnet-beta.solana.com';
    const connection = new Connection(RPC);
    const { publicKey } = useWallet();
    const [balance, setBalance] = useState<number>(0);

    const fetchBalance = async () => {
        if (publicKey) {
            try {
                const balance = await connection.getBalance(publicKey);
                const balanceInSol = balance / 1e9; // Convert lamports to SOL
                setBalance(parseFloat(balanceInSol.toFixed(2))); // Convert to two decimal places
            } catch (error) {
                console.error('Failed to fetch balance:', error);
            }
        }
    };

    useEffect(() => {
        fetchBalance();
    }, [publicKey]);

    const refreshBalance = () => {
        fetchBalance();
    };

    return (
        <SolBalanceContext.Provider value={{ balance, refreshBalance }}>
            {children}
        </SolBalanceContext.Provider>
    );
};
