import { createContext, ReactElement, useEffect } from "react";

const MyThemeContext = createContext({
  isDarkTheme: true,
});

interface ThemePropsInterface {
  children?: JSX.Element | Array<JSX.Element>;
}

export function MyThemeContextProvider(
  props: ThemePropsInterface
): ReactElement {
  useEffect(() => {
    setDarkTheme();
  }, []);

  function setDarkTheme(): void {
    localStorage.setItem("isDarkTheme", "true");
    document.querySelector("body")!.classList.add("dark");
    updateBackground();
  }

  function updateBackground(): void {
    document.body.style.backgroundColor = "#0e0e10";
    document.documentElement.style.backgroundColor = "#0e0e10";
  }

  return (
    <MyThemeContext.Provider value={{ isDarkTheme: true }}>
      {props.children}
    </MyThemeContext.Provider>
  );
}

export default MyThemeContext;
