import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const SelectedTokenContext = createContext(null);

export const SelectedTokenProvider = ({ children }) => {
  const [selectedToken, setSelectedToken] = useState(null);

  const updateSelectedToken = useCallback((token) => {
    //console.log("Attempting to update selected token:", token);
    setSelectedToken((prevToken) => {
      if (JSON.stringify(prevToken) !== JSON.stringify(token)) {
        //console.log("Updating selected token:", token);
        return token;
      }
      //console.log("Token unchanged, skipping update");
      return prevToken;
    });
  }, []);

  return (
    <SelectedTokenContext.Provider value={{ selectedToken, setSelectedToken: updateSelectedToken }}>
      {children}
    </SelectedTokenContext.Provider>
  );
};

export const useSelectedToken = () => {
  const context = useContext(SelectedTokenContext);
  if (context === undefined) {
    console.log('useSelectedToken must be used within a SelectedTokenProvider');
  }
  return context;
};