import React, { createContext, useContext, useState, useEffect, useRef, Dispatch, SetStateAction, ReactNode } from 'react';
import { useWallet } from "@solana/wallet-adapter-react";

interface Token {
    id: string;
    name: string;
    ticker: string;
    description: string;
    tokenAddress: string;
    avatar: string;
    likes: number;
}

interface TokensContextType {
    tokens: Token[];
    setTokens: Dispatch<SetStateAction<Token[]>>;
    loading: boolean;
    error: string | null;
    refreshTokens: () => Promise<void>;
}

const TokensContext = createContext<TokensContextType>({
    tokens: [],
    setTokens: () => { console.error("setTokens function must be overridden"); },
    loading: false,
    error: null,
    refreshTokens: async () => { console.error("refreshTokens function must be overridden"); }
});

export const useTokens = () => useContext(TokensContext);

interface TokensProviderProps {
    children: ReactNode;
}

export const TokensProvider: React.FC<TokensProviderProps> = ({ children }) => {
    const [tokens, setTokens] = useState<Token[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { publicKey } = useWallet();

    const generateRandomAddress = () => {
        return 'TOKEN_' + Math.random().toString(36).substring(2, 15);
    };

    const fetchTokens = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://api.mrkt.fun/ai/tokens?limit=20');
            if (!response.ok) {
                throw new Error('Failed to fetch tokens');
            }
            const data = await response.json();
            const processedTokens = data.data.tokens.map((token: Token) => ({
                ...token,
                tokenAddress: token.tokenAddress || generateRandomAddress(),
                avatar: `https://firebasestorage.googleapis.com/v0/b/vula-labs-loyalty.appspot.com/o/memes%2Ff77a3897-123f-42c0-815f-b9101ddd1a0b-IMG_0891.jpg?alt=media&token=f1877b29-d776-4966-b2c7-d67bbfe8c5cd`
            }));
            setTokens(processedTokens);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTokens();
    }, []);

    const refreshTokens = async () => {
        await fetchTokens();
    };

    return (
        <TokensContext.Provider value={{ tokens, setTokens, loading, error, refreshTokens }}>
            {children}
        </TokensContext.Provider>
    );
};