import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface CombinedData {
    id: string;
    pubkey: string;
    totalVolume: number;
    profitLoss: number;
    tradesCount: number;
    tokensCreated: number;
    account: {
        buyVolume: number;
        sellVolume: number;
        trades: string;
    };
    isFollowing?: boolean;
}

interface LeaderboardContextType {
    leaderboardData: CombinedData[];
    loading: boolean;
    error: string | null;
    sortConfig: { key: string | null; direction: 'ascending' | 'descending' };
    setSortConfig: React.Dispatch<React.SetStateAction<{ key: string | null; direction: 'ascending' | 'descending' }>>;
    handleSort: (key: string) => void;
    refreshLeaderboard: () => Promise<void>;
}

const LeaderboardContext = createContext<LeaderboardContextType>({
    leaderboardData: [],
    loading: true,
    error: null,
    sortConfig: { key: null, direction: 'descending' },
    setSortConfig: () => {},
    handleSort: () => {},
    refreshLeaderboard: async () => {},
});

export const useLeaderboard = () => useContext(LeaderboardContext);

interface LeaderboardProviderProps {
    children: ReactNode;
}

export const LeaderboardProvider: React.FC<LeaderboardProviderProps> = ({ children }) => {
    const [leaderboardData, setLeaderboardData] = useState<CombinedData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [sortConfig, setSortConfig] = useState({ key: null as string | null, direction: 'descending' as 'ascending' | 'descending' });

    const fetchLeaderboardData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get('https://api.mrkt.fun/users');
            const combinedData = response.data.combinedLeaderboard;
            setLeaderboardData(combinedData);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeaderboardData();
    }, []);

    const handleSort = (key: string) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const refreshLeaderboard = async () => {
        await fetchLeaderboardData();
    };

    const sortedLeaderboardData = React.useMemo(() => {
        let sortableItems = [...leaderboardData];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key as keyof CombinedData] < b[sortConfig.key as keyof CombinedData]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key as keyof CombinedData] > b[sortConfig.key as keyof CombinedData]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [leaderboardData, sortConfig]);

    return (
        <LeaderboardContext.Provider 
            value={{ 
                leaderboardData: sortedLeaderboardData, 
                loading, 
                error, 
                sortConfig, 
                setSortConfig, 
                handleSort, 
                refreshLeaderboard 
            }}
        >
            {children}
        </LeaderboardContext.Provider>
    );
};