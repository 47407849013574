import { AppProps } from 'next/app';
import React, { FC, useState } from 'react';
import { MyThemeContextProvider } from '../store/MyTheme';
import { ContextProvider } from '../contexts/ContextProvider';
import { NextSeo } from 'next-seo';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/next"
import { TokensProvider } from '../contexts/AvailableTokens';
import { LeaderboardProvider } from '../contexts/LeaderboardContext';
import { UserAuthContextProvider } from '../contexts/AuthContext';
import { SelectedTokenProvider, useSelectedToken } from '../contexts/SelectedToken';
import { TokensBalanceProvider } from '../contexts/SolBalance';
import AppBar from '../components/AppBar/AppBar';
import { useRouter } from 'next/router';
require('@solana/wallet-adapter-react-ui/styles.css');
require('../styles/globals.css');

const App: FC<AppProps> = ({ Component, pageProps }) => {

  // Wrap the entire component with SelectedTokenProvider
  return (
    <SelectedTokenProvider>
      <AppContent Component={Component} pageProps={pageProps} />
    </SelectedTokenProvider>
  );
};

const AppContent: FC<{ Component: any, pageProps: any }> = ({ Component, pageProps }) => {
  const router = useRouter();
  const [showHome, setShowHome] = useState(true)
  const [showTokenList, setShowTokenList] = useState(false);
  const [showCreateToken, setShowCreateToken] = useState(false);
  const [showInvite, setshowInvite] = useState(false);
  const [showLeaderboard, setshowLeaderboard] = useState(false);

  const handleHomeClick = () => {
    setShowHome(true);
    setShowCreateToken(false);
    setShowTokenList(false);
    setshowInvite(false);
    setshowLeaderboard(false);
    router.push('/')
  };

  const handleLaunchClick = () => {
    setShowCreateToken(true);
    setShowHome(false);
    setShowTokenList(false);
    setshowInvite(false);
    setshowLeaderboard(false);
    router.push('/')
  };

  const handleInviteClick = () => {
    setshowInvite(true);
    setShowHome(false);
    setShowTokenList(false);
    setShowCreateToken(false);
    setshowLeaderboard(false);
    router.push('/')
  };

  const handleTokenClick = () => {
    setShowTokenList(true);
    setShowHome(false);
    setShowCreateToken(false);
    setshowInvite(false);
    setshowLeaderboard(false);
    router.push('/')
  };

  const handleLeaderboardClick = () => {
    setshowLeaderboard(true);
    setShowHome(false);
    setShowTokenList(false);
    setShowCreateToken(false);
    setshowInvite(false);
    router.push('/')
  };

  return (
    <>
      <NextSeo
        title="MRKT"
        description="Onchain pvp marketplace."
        openGraph={{
          url: 'https://mrkt.fun',
          type: 'website',
          locale: 'en_IE',
          title: 'MRKT',
          description: 'Onchain pvp marketplace.',
          images: [
            {
              url: 'https://firebasestorage.googleapis.com/v0/b/vula-labs-loyalty.appspot.com/o/emojis%2FIMG_0891.jpg?alt=media&token=bec8a094-5398-4d71-b39f-1487063c291e',
              width: 1200,
              height: 630,
              alt: 'MRKT Header Image',
              type: 'image/png',
            },
          ],
          siteName: 'MRKT',
        }}
      />
      <MyThemeContextProvider>
        <ContextProvider>
          <UserAuthContextProvider>
            <TokensProvider>
                <TokensBalanceProvider>
                <LeaderboardProvider>
                  <div className="flex flex-col h-screen bg-[#0e0e10] text-white">
                    <script src="/theme.js"></script>
                   {/*  <AppBar
                      showHome={showHome}
                      showTokenList={showTokenList}
                      showCreateToken={showCreateToken}
                      showInvite={showInvite}
                      showLeaderboard={showLeaderboard}
                      handleHomeClick={handleHomeClick}
                      handleLaunchClick={handleLaunchClick}
                      handleInviteClick={handleInviteClick}
                      handleTokenClick={handleTokenClick}
                      handleLeaderboardClick={handleLeaderboardClick}
                    /> */}
                    <div className="flex-1 overflow-hidden">
                      <Component
                        {...pageProps}
                        showHome={showHome}
                        showTokenList={showTokenList}
                        showCreateToken={showCreateToken}
                        showInvite={showInvite}
                        showLeaderboard={showLeaderboard}
                        handleHomeClick={handleHomeClick}
                        handleLaunchClick={handleLaunchClick}
                        handleInviteClick={handleInviteClick}
                        handleTokenClick={handleTokenClick}
                        handleLeaderboardClick={handleLeaderboardClick}
                      />
                    </div>
                    <Analytics mode={'production'} />
                    <SpeedInsights />
                  </div>
                  </LeaderboardProvider>
                </TokensBalanceProvider>
            </TokensProvider>
          </UserAuthContextProvider>
        </ContextProvider>
      </MyThemeContextProvider>
    </>
  );
};

export default App;