import { createContext, useContext, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import { useRouter } from "next/router";

interface UserAuthContextType {
  user: any;
  //onlineUsersCount: number;
  referrer: string | null;
  referrals: ReferralResponse | null;
}

const userAuthContext = createContext<UserAuthContextType>({
  user: null,
  //onlineUsersCount: 0,
  referrer: null,
  referrals: null,
});


interface ReferredUser {
  id: string;
  walletId: string;
  // Add any other fields that your API returns for referred users
}

interface ReferralResponse {
  referralsCount: number;
  referrals?: ReferredUser[];
  message?: string;
}


async function handleReferrals(walletId: string): Promise<ReferralResponse | null> {
  try {
    const response = await axios.get<ReferralResponse>(`https://api.mrkt.fun/auth/referrals/${walletId}`);
    
    if (response.data.referralsCount > 0) {
      return {
        referralsCount: response.data.referralsCount,
        referrals: response.data.referrals
      };
    } else {
      return {
        referralsCount: 0,
        message: response.data.message || 'No referrals found'
      };
    }
  } catch (error) {
    console.error("Error getting referrals:", error);
    return null;
  }
}

async function handleDisconnect(walletId: string) {
  try {
    await axios.post('https://api.mrkt.fun/auth/disconnect', { walletId });
  } catch (error) {
    console.error("Error disconnecting:", error);
  }
}

async function handleConnect(walletId: string) {
  try {
    await axios.post('https://api.mrkt.fun/auth/connect', { walletId });
  } catch (error) {
    console.error("Error connecting:", error);
  }
}

async function handleReferrer(walletId: string): Promise<string | null> {
  try {
    const response = await axios.get(`https://api.mrkt.fun/auth/ref/${walletId}`);
    return response.data.referrer || null;
  } catch (error) {
    console.error("Error getting referrer:", error);
    return null;
  }
}


export function UserAuthContextProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { publicKey, connected } = useWallet();
  const wallet = publicKey?.toString();
  const [user, setUser] = useState(null);
  //const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const [referrer, setReferrer] = useState<string | null>(null);
  const [referrals, setReferrals] = useState<ReferralResponse | null>(null);

  useEffect(() => {
    const handleRefLink = async () => {
      const { ref } = router.query;
      if (ref && typeof ref === 'string' && wallet) {
        try {
          await axios.post(`https://api.mrkt.fun/auth/reflink/${wallet}`, { referrer: ref });
          // Optionally, you can update the referrer state here if needed
          setReferrer(ref);
          router.replace(router.pathname, undefined, { shallow: true });
        } catch (error) {
          console.error("Error processing reflink:", error);
        }
      }
    };

    if (connected && wallet) {
      handleRefLink();
    }
  }, [router.query, wallet, connected]);

  useEffect(() => {
    const fetchReferrerAndReferrals = async () => {
      if (wallet && connected) {
        const referrerAddress = await handleReferrer(wallet);
        setReferrer(referrerAddress);
  
        const referralsData = await handleReferrals(wallet);
        setReferrals(referralsData);
      }
    };
  
    fetchReferrerAndReferrals();
  }, [wallet, connected]);

  useEffect(() => {
    const handleConnectDisconnect = async (walletId: string, isConnected: boolean) => {
      if (!walletId) {
        return;
      }

      if (isConnected) {
        await handleConnect(walletId);
      } else {
        await handleDisconnect(walletId);
        setReferrals(null)
        setReferrer(null)
      }
    };

    if (connected && wallet) {
      handleConnectDisconnect(wallet, true);
    } else if (wallet) {
      handleConnectDisconnect(wallet, false);
    }

    return () => {
      if (wallet) {
        handleDisconnect(wallet);
        setReferrals(null)
        setReferrer(null)
      }
    };
  }, [wallet, connected]);

/*   useEffect(() => {
    const fetchOnlineUsersCount = async () => {
      try {
        const response = await axios.get('https://api.mrkt.fun/auth/onlineUsersCount');
        setOnlineUsersCount(response.data.count);
      } catch (error) {
        //console.error("Error fetching online users count:", error);
      }
    };

    fetchOnlineUsersCount();
    const interval = setInterval(fetchOnlineUsersCount, 30000); // Update every 30 seconds

    return () => clearInterval(interval); 
  }, []); */

  const userAuthContextValue = {
    user,
    //onlineUsersCount,
    referrer,
    referrals
  };

  return (
    <userAuthContext.Provider value={userAuthContextValue}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}